import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  Space,
  Typography,
  Divider,
  Alert,
  message,
  Modal,
} from "antd";
import { useApp } from "../store";
import { useNavigate, Navigate, useSearchParams } from "react-router-dom";
import { login, loginOtp } from "../api";
import { useState } from "react";
import balyLogo from "../assets/images/baly-logo.png";
import { useForm } from "antd/lib/form/Form";
import { QrModal } from "../comonents/global/qrModal";

const { Title } = Typography;

export const LoginPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [uri, setUri] = useState(null);
  const [isOtp, setIsOtp] = useState(false);
  const [otp, setOtp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isLogin, setIsLogin, setUser, setIsQrModal } = useApp();
  const [form] = useForm();
  const [searchParams] = useSearchParams();

  const onFinish = async (values) => {
    setIsLoading(true);
    localStorage.clear();
    login(values, (res, err) => {
      if (!err) {
        setIsLoading(false);
        if (res.success) {
          if (res.data.twoFaEnabled) setIsOtp(true);
          else {
            setUri(res?.data?.twoFaURI);
            setIsQrModal(true);
          }
        } else {
          setIsLoading(false);
          message.error(res.msg);
          setError(res.msg);
        }
      } else {
        setIsLoading(false);
        message.error(err.message);
        setError("Check the network and try again !");
      }
    });
  };

  const onLoginOtp = async (otpFromModal) => {
    setIsLoading(true);
    let values = {
      username: form.getFieldValue("username"),
      password: form.getFieldValue("password"),
      otp: otpFromModal || otp,
    };
    loginOtp(values, (res, err) => {
      if (!err) {
        if (res.success) {
          setIsLoading(false);
          setIsQrModal(false);
          localStorage.setItem("bo_token", res.data.token);
          localStorage.setItem("bo_refresh_token", res.data.refreshToken);
          localStorage.setItem("bo_user", JSON.stringify(res.data.user));
          setIsLogin(true);
          setUser(res.data.user);
          if (searchParams.get("p")) {
            navigate(searchParams.get("p"));
          } else {
            navigate("/");
          }
        } else {
          setIsLoading(false);
          message.error(res.err);
          setError(res.err);
        }
      } else {
        setIsLoading(false);
        message.error(err.message);
        setError("Check the network and try again !");
      }
    });
  };

  return isLogin ? (
    <Navigate to="/" />
  ) : (
    <div className="login-page">
      <div className="center">
        <Card
          className={"login-card"}
          headStyle={{ padding: "0px 40px" }}
          bodyStyle={{ padding: "20px 40px" }}
          title={
            <Space align="center">
              <img src={balyLogo} />
              <Title style={{ margin: 0, marginTop: 10 }} level={5}>
                Baly Dashboard
              </Title>
            </Space>
          }
          style={{ width: 400 }}
        >
          {error ? (
            <Alert
              style={{ marginBottom: 10 }}
              // message="Network Error"
              description={error}
              type="error"
              closable
              // onClose={onClose}
            />
          ) : null}

          <Form
            layout={"vertical"}
            name="basic"
            form={form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder="Example@baly.iq" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="******" />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Divider />

            <Form.Item>
              <Button
                loading={isLoading}
                block
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>

      <Modal
        className="otp-modal"
        visible={isOtp}
        closable={false}
        footer={false}
        destroyOnClose={true}
      >
        <div className="login-page">
          <div className="center">
            <Card
              className={"login-card"}
              headStyle={{ padding: "0px 40px" }}
              bodyStyle={{ padding: "20px 40px" }}
              title={
                <Space align="center">
                  <img src={balyLogo} />
                  <Title style={{ margin: 0, marginTop: 10 }} level={5}>
                    Baly Dashboard
                  </Title>
                </Space>
              }
              style={{ width: 400 }}
            >
              <Input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="OTP Code ..."
              />

              <Divider />

              <Button
                loading={isLoading}
                onClick={() => onLoginOtp()}
                block
                type="primary"
              >
                Send Otp
              </Button>
            </Card>
          </div>
        </div>
      </Modal>
      <QrModal isLoginPage uri={uri} onSave={onLoginOtp} loading={isLoading} />
    </div>
  );
};
