const LIVE_URL =
  process.env.REACT_APP_IS_DEV === "true"
    ? "https://back.baly.dev/api"
    : "https://back.food.baly.iq/api";

const WorldCup_URL =
  process.env.REACT_APP_IS_DEV === "true"
    ? "https://prediction-server-3nomdwg26a-ey.a.run.app/api"
    : "/api";

export const pageSize = 10;
let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
//const LIVE_URL = "https://647b-37-237-76-45.ngrok.io/api";

export const login = ({ username, password }, cb) => {
  fetch(`${LIVE_URL}/auth/login?tz=${tz}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
  })
    .then((res) => res.json())
    .then((jsonResults) => cb(jsonResults, null))
    .catch((e) => cb(null, e));
};

export const loginOtp = ({ username, password, otp }, cb) => {
  fetch(`${LIVE_URL}/auth/otp?tz=${tz}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      username,
      password,
      otp,
    }),
  })
    .then((res) => res.json())
    .then((jsonResults) => cb(jsonResults, null))
    .catch((e) => cb(null, e));
};

export const refresh = (cb) => {
  try {
    let bo_refresh_token = localStorage.getItem("bo_refresh_token");
    if (bo_refresh_token) {
      fetch(`${URL}/../auth/refresh?tz=${tz}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bo_refresh_token}`,
        },
      })
        .then((res) => res.json())
        .then((resp) => {
          cb(resp, null);
        })
        .catch((e) => cb(null, e));
    }
  } catch (err) {
    cb(null, err);
  }
};

export const getMatches = async (e) => {
  const res = await fetch(
    `${WorldCup_URL}/v1/match?page_size=${e.queryKey[3] || pageSize}&page_num=${
      e.queryKey[2] || 1
    }&tz=${tz}&term=${e.queryKey[1] || ""}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
      },
    }
  ).then((res) => res.json());

  return res;
};

export const editMatch = async (data) => {
  const res = await fetch(`${WorldCup_URL}/v1/match?tz=${tz}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

  return res;
};

export const editMatchResult = async (data) => {
  const res = await fetch(`${WorldCup_URL}/v1/match/result?tz=${tz}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

  return res;
};

export const createMatch = async (data) => {
  var formdata = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formdata.append(key, value);
  });
  const res = await fetch(`${WorldCup_URL}/v1/match?tz=${tz}`, {
    method: "POST",
    mode: "cors",
    headers: {
      //"Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
    body: formdata,
  }).then((res) => res.json());

  return res;
};

export const removeMatch = async (id) => {
  const res = await fetch(`${WorldCup_URL}/v1/match?id=${id}&tz=${tz}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
  }).then((res) => res.json());

  return res;
};

export const replaceImage = async (pathName, data) => {
  var formdata = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formdata.append(key, value);
  });
  const res = await fetch(`${WorldCup_URL}/v1/${pathName}?tz=${tz}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
    body: formdata,
  }).then((res) => res.json());

  return res;
};

export const getBanners = async (e) => {
  const res = await fetch(
    `${WorldCup_URL}/v1/banner?page_size=${pageSize}&page_num=${
      e.queryKey[2] || 1
    }&tz=${tz}&term=${e.queryKey[1] || ""}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
      },
    }
  ).then((res) => res.json());

  return res;
};

export const editBanner = async (data) => {
  var formdata = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formdata.append(key, value);
  });
  const res = await fetch(`${WorldCup_URL}/v1/banner?tz=${tz}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      //"Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
    body: formdata,
  }).then((res) => res.json());

  return res;
};

export const createBanner = async (data) => {
  var formdata = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    formdata.append(key, value);
  });
  const res = await fetch(`${WorldCup_URL}/v1/banner?tz=${tz}`, {
    method: "POST",
    mode: "cors",
    headers: {
      //"Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
    body: formdata,
  }).then((res) => res.json());

  return res;
};

export const removeBanner = async (id) => {
  const res = await fetch(`${WorldCup_URL}/v1/banner?id=${id}&tz=${tz}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
    },
  }).then((res) => res.json());

  return res;
};

export const getWinner = async (e) => {
  const res = await fetch(
    `${WorldCup_URL}/v1/prediction/winner?page_size=${pageSize}&page_num=${
      e.queryKey[2] || 1
    }&tz=${tz}&${e.queryKey[1] || ""}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("bo_token")}`,
      },
    }
  ).then((res) => res.json());

  return res;
};
