import {
    Form,
    Button,
    Typography,
    Modal,
    Row,
    Col,
    Divider,
    message,
    InputNumber,
  } from "antd";
  import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
  import { useMatch } from "../../store";
  import { useMutation } from "react-query";
  import { queryClient } from "../../queryClient";
  import { editMatchResult } from "../../api";
  import { useEffect } from "react";
  const { Title } = Typography;
  
  export const EditResult = () => {
    const {
        isCreateResultModal,
        setIsCreateResultModal,
        setId,
        id,
        resultA,
        resultB,
  
        setUuid,
        setTeamA,
        setTeamB,
        setStartTime,
        setResultA,
        setResultB,
        setLogoA,
        setLogob,
    } = useMatch();
  
    const [form] = Form.useForm();
  
    const resetState = () => {
      setId(null)
      setUuid(null)
      setTeamA(null)
      setTeamB(null)
      setStartTime(null)
      setResultA(null)
      setResultB(null)
      setLogoA(null)
      setLogob(null)
      form.resetFields();
    };
  
    const { mutate, isLoading: editLoading } = useMutation(
        editMatchResult,
      {
        onSuccess: (data) => {
          if (data.success) {
            message.success(data.msg);
            setIsCreateResultModal(false);
            queryClient.invalidateQueries("match");
            resetState();
          } else {
            message.error(data.msg);
          }
        },
        onError: (e) => {
          message.error(e.message);
        },
      }
    );
  
  
    const onFinish = (values) => {
      // console.log("values:", values)
        let _data={
            id: id,
            resultA: Number(values.resultA),
            resultB: Number(values.resultB),
        }
        mutate(_data)
    };
  
    useEffect(() => {
      if (id) {
        form.setFieldsValue({
          resultA,
          resultB,
        });
       
      } else {
        form.resetFields();
        resetState();
      }
    }, [id]);
  
    return (
        <Modal
            forceRender
            title={<Title level={5}>Edit Match Result</Title>}
            className="app-modal"
            centered
            visible={isCreateResultModal}
            bodyStyle={{ paddingTop: 5 }}
            onCancel={() => {
                resetState();
                setIsCreateResultModal(false);
            }}
            footer={false}
            destroyOnClose={true}
            width={350}
        >
            <Form
                requiredMark={"optional"}
                name="basic"
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                <Row gutter={[20, 20]}>
                        
                    <Col span={10}>
                        <Form.Item
                            label="Result A"
                            name="resultA"
                            rules={[{ required: true }]}
                        >
                            <InputNumber min={0} placeholder="team A Result" />
                        </Form.Item>
                    </Col>
                
                    <Col span={10}>
                        <Form.Item
                            label="Result B"
                            name="resultB"
                            rules={[{ required: true }]}
                        >
                            <InputNumber min={0} placeholder="team B Result" />
                        </Form.Item>
                    </Col>
                        
                    <Col span={24}>
                        <Divider />
                        <Form.Item>
                            <Button
                                loading={editLoading}
                                type="primary"
                                htmlType="submit"
                                icon={<SaveOutlined />}
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
  };
  