import "./App.css";
import {
  BannerPage,
	LoginPage,
  MatchPage,
  WinnerPage,
} from "./pages";
import { useApp } from "./store";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Layout, Spin } from "antd";
import { AppHeader } from "./comonents";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./queryClient";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect } from "react";
import { CustomErrorBoundary } from "./comonents/CustomErrorBoundry/index";
import authFunc from "./helper/auth";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { refresh } from "./api";

const { Content } = Layout;

function App() {
  const { isLogin, setIsLogin, setUser, appLoading, setAppLoading } = useApp();
  let location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("bo_token");
    const _user = localStorage.getItem("bo_user");
    if (token && _user) {
      setIsLogin(true);
      setUser(JSON.parse(_user));
    }
  }, []);

  const loagout = () => {
    setAppLoading(false);
    setIsLogin(false);
    setUser(null);
    localStorage.clear();
  };

  const refreshToken = async () => {
    setAppLoading(true);
    refresh(async (resp, err) => {
      if (err) return loagout();
      if (resp.success) {
        setAppLoading(false);
        localStorage.setItem("bo_token", resp.data.token);
        localStorage.setItem("bo_refresh_token", resp.data.refreshToken);
      } else {
        loagout();
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("bo_token");
    if (token) {
      var decoded = jwt_decode(token);
      if (!dayjs().isAfter(dayjs(decoded.exp))) {
        refreshToken();
      } else {
        setAppLoading(false);
      }
    } else {
      loagout();
    }
  }, [location]);

  return (
		<CustomErrorBoundary>
			<QueryClientProvider client={queryClient}>
				<Layout className='layout'>
					{isLogin ? (
						location?.pathname.split("/")[1] !== "order" ? (
							<AppHeader />
						) : null
					) : null}
					<Content className='app-content'>
						<div className='app-container' style={{ marginTop: 40 }}>
							<Spin spinning={appLoading}>
								{!appLoading && (
									<Routes>
										<Route exact path='/login' element={<LoginPage />} />

										<Route
											exact
											path='/'
											element={
												<PrivateRoute Component={MatchPage} auth={isLogin} />
											}
										/>
										<Route
											path='/banner'
											element={
												<PrivateRoute Component={BannerPage} auth={isLogin} />
											}
										/>
                    <Route
											path='/winner'
											element={
												<PrivateRoute Component={WinnerPage} auth={isLogin} />
											}
										/>
									</Routes>
								)}
							</Spin>
						</div>
					</Content>
				</Layout>
				<ReactQueryDevtools initialIsOpen />
			</QueryClientProvider>
		</CustomErrorBoundary>
	);
}

const PrivateRoute = ({ Component, auth }) => {
  return auth ? <Component /> : <Navigate to="/login" />;
};

export default App;
