import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
  Upload,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useBanner } from "../../store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createBanner, editBanner } from "../../api";
import { useEffect } from "react";
const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = () => {
  const {
      isCreateModal,
      setIsCreateModal,
      setId,
      name,
      action,
      setAction,
      setName,
      id,
      active,
  } = useBanner();

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const [form] = Form.useForm();

  const resetState = () => {
    setId(null)
    setAction(null)
    setName(null)
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(createBanner, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("banner");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editBanner,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("banner");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );

  const onFinish = (values) => {
    // console.log("values:", values)
    if (id) {
      let dataForm = {
        id: id,
        name: values.name,
        action: values.action || "",
        active: active,
      };
      editMutate(dataForm);
    }else{
      let dataForm = {
        name: values.name,
        action: values.action || "",
        imgFile: values.imgFile.file,
        active: "false",
    };
      mutate(dataForm)
    }

  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        action,
        name,
      });
     
    } else {
      form.resetFields();
      resetState();
    }
  }, [id]);

  return (
      <Modal
          forceRender
          title={<Title level={5}>{id ? "Edit" : "New"} Banner</Title>}
          className="app-modal"
          centered
          visible={isCreateModal}
          bodyStyle={{ paddingTop: 5 }}
          onCancel={() => {
              resetState();
              setIsCreateModal(false);
          }}
          footer={false}
          destroyOnClose={true}
          width={400}
      >
          <Form
              requiredMark={"optional"}
              name="basic"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
          >
            <Row gutter={[20, 20]}>
              <Col span={24}>
                  <Form.Item
                      label="Name"
                      name="name"
                      rules={[{ required: true }]}
                  >
                      <Input placeholder="Write Banner name" />
                  </Form.Item>
              </Col>
              <Col span={24}>
                  <Form.Item
                      label="Action"
                      name="action"
                      rules={[{ required: false }]}
                  >
                      <Input placeholder="Write an Action" />
                  </Form.Item>
              </Col>

              {!id ? (
                      <Col span={24}>
                        <Form.Item
                          label="Image"
                          name="imgFile"
                          rules={[{ required: true }]}
                        >
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint">
                              Click or drag Image to this area
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                    ) : null }

              <Col span={24}>
                  <Divider />
                  <Form.Item>
                      <Button
                          loading={isLoading || editLoading}
                          type="primary"
                          htmlType="submit"
                          icon={<SaveOutlined />}
                      >
                          Save
                      </Button>
                  </Form.Item>
              </Col>
            </Row>
          </Form>
      </Modal>
  );
};
