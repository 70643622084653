import { Card, Breadcrumb, Button } from "antd";
import WinnerFilters from "../comonents/Filters/winnerFilters";

import { PureTable } from "../comonents/winner";
import { useWinner } from "../store";

export const WinnerPage = () => {
  const { setIsExport } = useWinner()
  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Matches</Breadcrumb.Item>
        <Breadcrumb.Item>Winner</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
            <WinnerFilters />
            <Button onClick={()=>setIsExport(true)} type="primary"> Export to excel </Button>
        </div>
        <br />
        <PureTable />
      </Card>
    </div>
  );
};