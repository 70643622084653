import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  Input,
  message,
  Upload,
  DatePicker,
} from "antd";
import { SaveOutlined, InboxOutlined } from "@ant-design/icons";
import { useMatch } from "../../store";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { createMatch, editMatch } from "../../api";
import moment from "moment";
import { useEffect } from "react";
import dayjs from "dayjs";
const { Title } = Typography;
const { Dragger } = Upload;

export const PureCreate = () => {
  const {
      isCreateModal,
      setIsCreateModal,
      setId,
      teamA,
      teamB,
      id,
      startTime,
      resultA,
      resultB,

      setUuid,
      setTeamA,
      setTeamB,
      setStartTime,
      setResultA,
      setResultB,
      setLogoA,
      setLogob,
  } = useMatch();

  const [form] = Form.useForm();

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      return false;
    },
  };

  const resetState = () => {
    setId(null)
    setUuid(null)
    setTeamA(null)
    setTeamB(null)
    setStartTime(null)
    setResultA(null)
    setResultB(null)
    setLogoA(null)
    setLogob(null)
    form.resetFields();
  };

  const { mutate, isLoading } = useMutation(createMatch, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        setIsCreateModal(false);
        queryClient.invalidateQueries("match");
        resetState();
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });
  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editMatch,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          setIsCreateModal(false);
          queryClient.invalidateQueries("match");
          resetState();
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );


  const onFinish = (values) => {
    // console.log("values:", values)
    if (id) {
      let _data={
        id: id,
        teamA: values.teamA,
        teamB: values.teamB,
        startTime: values.startTime
      }
      editMutate(_data)
    }else{
      let dataForm = {
        logoAImg: values.logoA.file,
        logoBImg: values.logoB.file,
        teamA: values.teamA,
        teamB: values.teamB,
        startTime: dayjs(values.startTime).toJSON(),
    };
      mutate(dataForm)
    }

  };

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        teamB,
        teamA,
        resultA,
        resultB,
        startTime: startTime ? moment(startTime) : null,
      });
     
    } else {
      form.resetFields();
      resetState();
    }
  }, [id]);

  return (
      <Modal
          forceRender
          title={<Title level={5}>{id ? "Edit" : "New"} Match</Title>}
          className="app-modal"
          centered
          visible={isCreateModal}
          bodyStyle={{ paddingTop: 5 }}
          onCancel={() => {
              resetState();
              setIsCreateModal(false);
          }}
          footer={false}
          destroyOnClose={true}
          width={550}
      >
          <Form
              requiredMark={"optional"}
              name="basic"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
          >
              <Row gutter={[20, 20]}>
                      <Col span={12}>
                          <Form.Item
                              label="Team A"
                              name="teamA"
                              rules={[{ required: true }]}
                          >
                              <Input placeholder="team A name" />
                          </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                            label="Team B"
                            name="teamB"
                            rules={[{ required: true }]}
                        >
                            <Input placeholder="team B name" />
                        </Form.Item>
                      </Col>

                    {!id ? (
                      <Col span={12}>
                        <Form.Item
                          label="Logo A"
                          name="logoA"
                          rules={[{ required: true }]}
                        >
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint">
                              Click or drag Image to this area
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                    ) : null }

                    {!id ? (
                      <Col span={12}>
                        <Form.Item
                          label="Logo B"
                          name="logoB"
                          rules={[{ required: true }]}
                        >
                          <Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint">
                              Click or drag Image to this area
                            </p>
                          </Dragger>
                        </Form.Item>
                      </Col>
                    ) : null }

                    <Col span={12}>
                      <Form.Item
                          label="start Time"
                          name="startTime"
                          rules={[{ required: true }]}
                      >
                          <DatePicker
                              style={{ width: "100%" }}
                              format="YYYY-MM-DD HH:mm:ss"
                              showTime
                          />
                      </Form.Item>
                    </Col>
                  <Col span={24}>
                      <Divider />
                      <Form.Item>
                          <Button
                              loading={isLoading || editLoading}
                              type="primary"
                              htmlType="submit"
                              icon={<SaveOutlined />}
                          >
                              Save
                          </Button>
                      </Form.Item>
                  </Col>
              </Row>
          </Form>
      </Modal>
  );
};
