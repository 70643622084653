import create from "zustand";

export const useWinner = create((set) => ({
    
    page: 1,
    queryFilter: "",
    from: null,
    to: null,
    isExport: false,

    setPage: (page) => set({ page }),
    setFrom: (from) => set({ from }),
    setTo: (to) => set({ to }),
    setIsExport: (isExport) => set({ isExport }),
    setQueryFilter: (queryFilter) => set({ queryFilter}),
}));