import { useState, useEffect } from "react";
import { Table, Space, Button, Typography, message, Alert, Popconfirm , Pagination, Tooltip} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { getMatches, removeMatch, pageSize} from "../../api";
import { useApp, useMatch } from "../../store";
// import authFunc from "../../helper/auth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { ImageTable } from "../global";

const { Text } = Typography;

export const PureTable = () => {
  const { setIsLogin } = useApp();
  const [page, setPage]= useState(1)
  const {
    setId,
    setUuid,
    setTeamA,
    setTeamB,
    setStartTime,
    setResultA,
    setResultB,
    setLogoA,
    setLogob,
    queryKey,
    setIsCreateModal,
    setIsCreateResultModal,
  } = useMatch();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id)
    setUuid(row.uuid)
    setTeamA(row.teamA)
    setTeamB(row.teamB)
    setStartTime(row.startTime)
    setResultA(row.resultA === -1 ? null : row.resultA)
    setResultB(row.resultB === -1 ? null : row.resultB)
    setLogoA(row.logoA)
    setLogob(row.logoB)
    setIsCreateModal(true)
  };

  const handleEditResult = (row) => {
    setId(row.id)
    setResultA(row.resultA === -1 ? null : row.resultA)
    setResultB(row.resultB === -1 ? null : row.resultB)
    setIsCreateResultModal(true)
  };
 
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Team A",
      dataIndex: "teamA",
      key: "teamA",
      align: "center",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Logo A",
      key: "id",
      width: 100,
      align: "center",
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.logoA}
          id={row.id}
          pathName={"match/logoA"}
          imgKey={"logoAImg"}
          updateStore={"match"}
        />
      ),
    },
    {
      title: "Result A",
      dataIndex: "resultA",
      key: "resultA",
      align: "center",
      render: (res) => <Text style={{ fontWeight: "bold" }}>{res === -1 ? "..." : res}</Text>,
    },
    {
      title: "Result B",
      dataIndex: "resultB",
      key: "resultB",
      align: "center",
      render: (res) => <Text style={{ fontWeight: "bold" }}>{res === -1 ? "..." : res}</Text>,
    },
    {
      title: "Logo B",
      key: "id",
      width: 100,
      align: "center",
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.logoB}
          id={row.id}
          pathName={"match/logoB"}
          imgKey={"logoBImg"}
          updateStore={"match"}
        />
      ),
    },
    {
      title: "Team B",
      dataIndex: "teamB",
      key: "teamB",
      align: "center",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (val) => <Text type="secondary">{dayjs(val).format("MMMM D, YYYY h:mm A")}</Text>,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      key: "updated_by",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space size="small">

          <Tooltip placement="top" title={"Edit Match"}>
            <Button onClick={()=> handleEdit(row)} size="small" icon={<EditOutlined />} />
          </Tooltip>

          <Tooltip placement="top" title={"Edit Result"}>
            <Button onClick={()=> handleEditResult(row)} size="small" icon={<FormOutlined />} />
          </Tooltip>

          <Popconfirm
            title="Are you sure to delete this Match?"
            onConfirm={() => mutate(id)}
            //onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>

        </Space>
      ),
    },
  ];

  const { mutate, isLoading: addLoading } = useMutation(
    (val) => removeMatch(val),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("match");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        //console.log(e);
        message.error(e.message);
      },
    }
  );

  const { isLoading: loading, error, data } = useQuery(["match", queryKey, page],
  getMatches,
   {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (data && data.err == "unauthorized") {
      localStorage.clear();
      setIsLogin(false);
    }
  }, [data]);

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data.success)
    return <Alert message={data.msg} type="error" showIcon />;

  return (
    <>
    <Table
    loading={loading}
    columns={columns}
    dataSource={data ? data.data.records : []}
    size="small"
    rowKey={(record) => record.id}
    pagination={false}
  />
  <br/>
  <Pagination
    onChange={(val) =>{ 
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
    current={Number(searchParams.get("page_num")) || page}
    defaultPageSize={pageSize}
    total={data && data.data.total}
    showSizeChanger={false}
  />
   </>
  
  );
};
