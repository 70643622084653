import { Layout, Dropdown, Space, Typography, Avatar, Menu } from "antd";

import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  TrophyOutlined,
  PictureOutlined
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useApp } from "../store";
import { useState, useEffect } from "react";
import authFunc from "../helper/auth";
import balyLogo from '../assets/images/baly-logo.png'
import worldCupLogo from '../assets/images/worldCup2022-logo.png'


const { Header } = Layout;
const { Title, Text } = Typography;
const { SubMenu } = Menu;

export const AppHeader = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const {
    setIsLogin,
    setIsEditAccount,
    setIsChangePassword,
    setUser,
    user,
  } = useApp();
  const [current, setCurrent] = useState("orders");

  useEffect(() => {
    setCurrent(location.pathname.substr(1));
  }, [location]);

  const menu = (
    <Menu>
      <Menu.Item
        danger
        onClick={() => {
          localStorage.clear("bo_token");
          setUser(null);
          setIsLogin(false);
        }}
      >
        <Space>
          <LogoutOutlined />
          Sign Out
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
		<Header className='app-header'>
			<div className='app-space-between app-container'>
				<Space align='center' style={{ flex: 1 }}>
					<img style={{marginRight: "10px"}} width={"20px"} height={"40px"} src={worldCupLogo} />
					<img width={"70px"} src={balyLogo} />
					<Title style={{ margin: 0, marginTop: 13, fontSize: 14 }} level={5}>
						World Cup Dashboard
					</Title>
				</Space>

				<Menu
					onClick={(e) => {
						navigate(`/${e.key}`);
						setCurrent(e.key);
					}}
					selectedKeys={[current]}
					//defaultSelectedKeys={location.pathname.substr(1)}

					mode='horizontal'
					className='app-menu'
					style={{ flex: 3 }}>
					<SubMenu key='orders' icon={<TrophyOutlined />} title='Matches'>
						<Menu.Item key=''>Match List</Menu.Item>
						<Menu.Item key='winner'>Winners</Menu.Item>
					</SubMenu>
					<SubMenu key='Banners' icon={<PictureOutlined />} title='Banners'>
						<Menu.Item
							key='banner'
						>
							Banner
						</Menu.Item>
					</SubMenu>
	
				</Menu>
				<Space style={{ flex: 1 }} className='app-justify-right'>
					<Dropdown overlay={menu}>
						<Space
							size={5}
							className='ant-dropdown-link'
							onClick={(e) => e.preventDefault()}>
							<DownOutlined />
							<Text>{user ? user.name : "..."}</Text>
						</Space>
					</Dropdown>
					<Avatar size='small' icon={<UserOutlined />} />
				</Space>
			</div>
		</Header>
	);
};
