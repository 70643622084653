import create from "zustand";

export const useApp = create((set) => ({
  isLogin: true,
  isEditAccount: false,
  isChangePassword: false,
  pageSize: 10,
  user: null,
  appLoading: true,
  isSubProduct: false,
  subProductSelected: null,
  subProducstInit: null,
  isSubProductEdit: false,
  subProductEditData: null,
  isQrModal: false,
  setIsQrModal: (isQrModal) => set({ isQrModal }),
  setSubProductEditData: (subProductEditData) => set({ subProductEditData }),
  setIsSubProductEdit: (isSubProductEdit) => set({ isSubProductEdit }),
  setIsSubProduct: (isSubProduct) => set({ isSubProduct }),
  setSubProducstInit: (subProducstInit) => set({ subProducstInit }),
  setSubProductSelected: (subProductSelected) => set({ subProductSelected }),
  setAppLoading: (appLoading) => set({ appLoading }),
  setPageSize: (pageSize) => set({ pageSize }),
  setUser: (user) => set({ user }),
  setIsEditAccount: (isEditAccount) => set({ isEditAccount }),
  setIsChangePassword: (isChangePassword) => set({ isChangePassword }),
  setIsLogin: (isLogin) => set({ isLogin }),
}));
