import React from 'react'
import { Select, Spin, DatePicker, Space } from "antd";
import { useWinner } from "../../store";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { getMatches } from "../../api";
import { useSearchParams } from "react-router-dom";
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function WinnerFilters() {
  const { setQueryFilter, setPage, setFrom, setTo, from, to } = useWinner();
  const [matchSearch, setMatchSearch] = useState("");
  const [matchID, setMatchID] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [from, setFrom] = useState(null);
  // const [to, setTo] = useState(null);

  const objToQueryString = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) {
        delete obj[key];
      }
    });

    let _queryString = Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&");

    return _queryString;
  };

  const makeFilter = ({ ...obj }, isInit = true) => {
    let queryObj = {
      ...obj,
    };
    
    if(isInit) return setSearchParams(objToQueryString(queryObj));
    if(!isInit) return setQueryFilter(objToQueryString(queryObj));
  };


  useEffect(() => {
      let obj = {
        matchID: Number(searchParams.get("matchID")) || null,
        from: searchParams.get("from") || null,
        to: searchParams.get("to") || null,
      };
  
      makeFilter(obj, false);
      setMatchID(obj.matchID);
      setFrom(obj.from);
      setTo(obj.to);
      setPage(obj.page_num);
    
  }, [
    searchParams.get("matchID"),
    searchParams.get("from"),
    searchParams.get("to"),
    searchParams.get("page_num"),
  ]);

  const { isLoading: loading, error, data } = useQuery(["match", matchSearch, 1, 500],
  getMatches,
   {
    refetchOnWindowFocus: false,
    retry: 2,
  });


  const SelectTimeDate = (value, dateString) => {
    let from = JSON.stringify(dateString[0]);
    let to = JSON.stringify(dateString[1]);

    if (value) {
      setFrom(from);
      setTo(to);
      makeFilter({ from, to, page_num: 1  });
    }else{
      setFrom(null);
      setTo(null);
      makeFilter({ matchID, page_num: 1  });
    }

  };


    return (
        
        <div className="filter-wrapper">
            <div className="item">
            <Select
                filterOption={false}
                dropdownMatchSelectWidth={false}
                notFoundContent={
                    loading ? (
                    <div className="spin-wrapper">
                    <Spin size="small" />
                    </div>
                ) : null
                }
                allowClear
                showSearch
                onChange={(val) =>{ 
                  setMatchID(val);
                  makeFilter({ matchID: val, page_num: 1});
                }}
                onClear={() => {
                  setMatchID(null);
                  setMatchSearch(null)
                }}
                onSearch={(val) =>{ 
                    setMatchSearch(val);
                }}
                placeholder="Select Match"
                style={{ width: "100%" }}
                value={matchID}
                >
                {data &&
                data.success &&
                data.data.records.map((el) => (
                    <Option key={el.id} value={el.id}>
                    {el.teamA} VS {el.teamB}
                    </Option>
                ))}
                </Select>
             </div>
             <div className="item">
                <Space direction="vertical" size={5}>
                  <RangePicker
                    value={from && to ? [moment(from, 'YYYY-MM-DD HH:mm'), moment(to, 'YYYY-MM-DD HH:mm')] : []}
                    showTime={{
                        format: "HH:mm"
                    }}
                    format="YYYY-MM-DD HH:mm"
                    onChange={SelectTimeDate}
                  />
                </Space>
              </div>
        </div>
    )
}
