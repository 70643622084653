import { Button, Typography, Modal, Input, Space, Divider } from "antd";

import { useApp } from "../../store";

import { SaveOutlined } from "@ant-design/icons";
import { useState } from "react";
import QRCode from "react-qr-code";

const { Title, Text } = Typography;

export const QrModal = ({ uri, onSave = () => {}, loading }) => {
  const { setIsQrModal, isQrModal } = useApp();
  const [otp, setOtp] = useState(null);

  return (
    <Modal
      title={<Title level={5}>Qr Generate</Title>}
      className="app-modal"
      centered
      visible={isQrModal}
      onCancel={() => {
        setOtp(null);
        setIsQrModal(false);
      }}
      footer={false}
      destroyOnClose={true}
      width={400}
      bodyStyle={{
        paddingTop: 10,
      }}
    >
      <Space align="baseline" size={2}>
        <Title level={5}>1 -</Title>{" "}
        <Text type="secondary">Scan The Qr Code</Text>
      </Space>
      <div
        style={{
          height: "auto",
          margin: "0 auto",
          width: "100%",
          padding: 80,
          border: "1px solid #eee",
          borderRadius: 8,
          background: "#eee",
        }}
      >
        {uri && (
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={uri}
          />
        )}
      </div>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Space align="baseline" size={2}>
        <Title level={5}>2 -</Title>{" "}
        <Text type="secondary">Then enter your otp code</Text>
      </Space>
      <Input
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        placeholder="Otp Code"
      />
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <Button
        icon={<SaveOutlined />}
        disabled={!otp}
        loading={loading}
        onClick={() => onSave(otp)}
        block
      >
        Save
      </Button>
    </Modal>
  );
};
