import { useState, useEffect } from "react";
import { Table, Space, Button, Typography, message, Alert, Popconfirm , Pagination, Switch, Tooltip} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../queryClient";
import { getBanners, removeBanner, editBanner, pageSize} from "../../api";
import { useApp, useBanner } from "../../store";
import { useSearchParams } from "react-router-dom";
import { ImageTable } from "../global";

const { Text, Link } = Typography;
const copy = require("clipboard-copy");

export const PureTable = () => {
  const { setIsLogin } = useApp();
  const [page, setPage]= useState(1)
  const {
    setIsCreateModal,
    setId,
    setAction,
    setName,
    setActive,
    queryKey,
  } = useBanner();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);

  const handleEdit = (row) => {
    setId(row.id)
    setName(row.name)
    setAction(row.action)
    setActive(row.active)
    setIsCreateModal(true)
  };

  const copyLink = (val) => {
    let successPromise = copy(val);
    if (successPromise) {
      message.success("Copy to clipboard");
    } else {
      message.error("Faild Copy !");
    }
  };

  const handleChangeActive = (row) => {
    let dataForm = {
      id: row.id,
      name: row.name,
      action: row.action,
      active: row.active === "true" ? false : true,
    };
    editMutate(dataForm);
  };
 
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      key: "id",
      width: 100,
      render: (row) => (
        <ImageTable
          logo={false}
          url={row.img}
          id={row.id}
          pathName={"banner/img"}
          imgKey={"imgFile"}
          updateStore={"banner"}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text style={{ fontWeight: "bold" }}>{text}</Text>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => (
          <Space className="table-link">
              <Link
                  className="app-media-link"
                  style={{ textDecoration: "underline" }}
              >
                  {text}
              </Link>
              <Tooltip placement="top" title="copy image link">
                  <Button
                      onClick={() => copyLink(text)}
                      size="small"
                      type="text"
                      icon={<CopyOutlined />}
                  />
              </Tooltip>
          </Space>
      ),
  },
    {
      title: "Status",
      key: "id",
      width: 60,
      render: (row) => (
        <Switch
          // size="small"
          // loading={editLoading}
          checked={row.active === "true" ? true : false}
          onChange={() => handleChangeActive(row)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id, row) => (
        <Space size="small">
          <Button onClick={()=> handleEdit(row)} size="small" icon={<EditOutlined />} />

          <Popconfirm
            title="Are you sure to delete this Banner?"
            onConfirm={() => mutate(id)}
            //onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger icon={<DeleteOutlined />} />
          </Popconfirm>

        </Space>
      ),
    },
  ];

  const { mutate, isLoading: addLoading } = useMutation(
    (val) => removeBanner(val),
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("banner");
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        //console.log(e);
        message.error(e.message);
      },
    }
  );

  const { mutate: editMutate, isLoading } = useMutation(editBanner, {
    onSuccess: (data) => {
      if (data.success) {
        message.success(data.msg);
        queryClient.invalidateQueries("banner");
      } else {
        message.error(data.msg);
      }
    },
    onError: (e) => {
      message.error(e.message);
    },
  });

  const { isLoading: loading, error, data } = useQuery(["banner", queryKey, page],
  getBanners,
   {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (data && data.err == "unauthorized") {
      localStorage.clear();
      setIsLogin(false);
    }
  }, [data]);

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data.success)
    return <Alert message={data.msg} type="error" showIcon />;

  return (
    <>
    <Table
    loading={loading}
    columns={columns}
    dataSource={data ? data.data.records : []}
    size="small"
    rowKey={(record) => record.id}
    pagination={false}
  />
  <br/>
  <Pagination
    onChange={(val) =>{ 
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
    current={Number(searchParams.get("page_num")) || page}
    defaultPageSize={pageSize}
    total={data && data.data.total}
    showSizeChanger={false}
  />
   </>
  
  );
};
