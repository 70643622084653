import create from "zustand";

export const useMatch = create((set) => ({

    isCreateModal: false,
    isCreateResultModal: false,
    id: null,
    queryKey: "",
    uuid: null,
    teamA: null,
    teamB: null,
    startTime: null,
    resultA: null,
    resultB: null,
    logoA: null,
    logoB: null,
    
    setQueryKey: (queryKey) => set({ queryKey }),
    setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
    setIsCreateResultModal: (isCreateResultModal) => set({ isCreateResultModal }),
    setUuid: (uuid) => set({ uuid }),
    setTeamA: (teamA) => set({ teamA }),
    setTeamB: (teamB) => set({ teamB }),
    setStartTime: (startTime) => set({ startTime }),
    setResultA: (resultA) => set({ resultA }),
    setResultB: (resultB) => set({ resultB }),
    setLogoA: (logoA) => set({ logoA }),
    setLogob: (logoB) => set({ logoB }),
    setId: (id) => set({ id }),
}));