import { useEffect } from "react";
import { Menu, Dropdown, message, Skeleton } from "antd";
import { PictureOutlined, FormOutlined } from "@ant-design/icons";
import { fileDialog } from "file-select-dialog";
import { replaceImage } from "../../api";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";

export const ImageTable = ({
  url,
  logo,
  pathName,
  id,
  imgKey,
  updateStore,
  
}) => {
  const { mutate, isLoading } = useMutation(
    (values) => replaceImage(pathName, values),
    {
      onSuccess: (data) => {
        // console.log(data);
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries(updateStore);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        // console.log(e);
        message.error(e.message);
      },
    }
  );

  // useEffect(()=>{
  //   if(isLoading){

  //   }
  //   message.loading("Uploading Iamge....")
  // },[isLoading])

  const openFile = async () => {
    const fileList = await fileDialog();
    let formData = {};
    formData.id = id;
    formData[imgKey] = fileList[0];
    mutate(formData);
  };
  const menu = (
    <Menu>
      <Menu.Item key="view" icon={<PictureOutlined />}>
        <a target="_blank" href={url}>
          View
        </a>
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="replace" disabled={!updateStore} icon={<FormOutlined />} onClick={openFile}>
        Replace
      </Menu.Item>
    </Menu>
  );

  return isLoading ? (
    <Skeleton.Avatar
      loading={true}
      active={true}
      round={true}
      size={"large"}
      style={{width: 50, height:50}}
    />
  ) : (
    <Dropdown overlay={menu}>
      <div className="img-placeholder">

      <img
        style={{
          width: logo ? 50 : 80,
          height: 50,
          borderRadius: logo ? 100 : 5,
          objectFit: "cover",
        }}
        
        src={url}
      />
      </div>
    </Dropdown>
  );
};
