import { useState, useEffect } from "react";
import { Table, Typography, Alert , Pagination, Button} from "antd";
import { useQuery } from "react-query";
import { getWinner, pageSize } from "../../api";
import { useApp, useWinner } from "../../store";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { ImageTable } from "../global";
import { Excel } from "antd-table-saveas-excel";

const { Text } = Typography;

function parseFilter(queryString) {
  let params = Object.fromEntries(new URLSearchParams(queryString));
  // console.log("params: ", params);

  if (params.from && params.to) {
    params.from = dayjs(JSON.parse(params.from)).toISOString();
    params.to = dayjs(JSON.parse(params.to)).toISOString();
  }

  let _queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  return _queryString;
}

export const PureTable = () => {
  const { setIsLogin } = useApp();
  const [page, setPage]= useState(1)
  const {
  queryFilter,
  isExport,
  setIsExport,
  } = useWinner();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("page_num")) {
      setPage(searchParams.get("page_num"));
    } else {
      setPage(1);
    }
  }, [searchParams.get("page_num")]);


  useEffect(()=>{
    if (isExport) {
      const excel = new Excel();
      excel
        .addSheet("test")
        .addColumns(columns)
        .addDataSource(data && data?.success ? data?.data?.records : [], {
          str2Percent: true
        })
        .saveAs("Excel.xlsx");

      setIsExport(false)
    }
  },[isExport])


  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Client ID",
      dataIndex: "clientID",
      key: "clientID",
    },
    {
      title: "Match ID",
      dataIndex: "matchID",
      key: "matchID",
    },
    {
      title: "Score A",
      dataIndex: "scoreA",
      key: "scoreA",
    },
    {
      title: "Score B",
      dataIndex: "scoreB",
      key: "scoreB",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
  ];


  const { isLoading: loading, error, data } = useQuery(["winner", parseFilter(queryFilter), page],
  getWinner,
   {
    refetchOnWindowFocus: false,
    retry: 2,
  });

  useEffect(() => {
    if (data && data.err == "unauthorized") {
      localStorage.clear();
      setIsLogin(false);
    }
  }, [data]);

  if (error) return <Alert message={error.message} type="error" showIcon />;

  if (!loading && !data?.success)
    return <Alert message={data?.msg} type="error" showIcon />;

  return (
    <>
    <Table
    loading={loading}
    columns={columns}
    dataSource={data ? data.data.records : []}
    size="small"
    rowKey={(record) => record.id}
    pagination={false}
  />
  <br/>
  <Pagination
    onChange={(val) =>{ 
          searchParams.set("page_num", val);
          setSearchParams(searchParams);
          setPage(val);
        }}
    current={Number(searchParams.get("page_num")) || page}
    defaultPageSize={pageSize}
    total={data && data.data.total}
    showSizeChanger={false}
  />
   </>
  
  );
};
