import create from "zustand";

export const useBanner = create((set) => ({

    isCreateModal: false,
    id: null,
    queryKey: "",
    name: null,
    action: null,
    active: "",

    setQueryKey: (queryKey) => set({ queryKey }),
    setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
    setName: (name) => set({ name }),
    setAction: (action) => set({ action }),
    setActive: (active) => set({ active }),
    setId: (id) => set({ id }),
}));